// CarMovingBody.ts
// Based on https://www.asawicki.info/Mirror/Car%20Physics%20for%20Games/Car%20Physics%20for%20Games.html

import { MovingBody } from "./MovingBody";

export class CarMovingBody extends MovingBody {
  protected velocityX: number = 0; // Velocity along the x-axis
  protected velocityY: number = 0; // Velocity along the y-axis
  protected angularVelocity: number = 0; // Angular velocity (for rotation)
  protected readonly maxSpeed: number = 5; // Max linear speed
  protected readonly acceleration: number = 0.5; // Acceleration rate
  protected readonly brakingPower: number = 1; // Braking deceleration
  protected readonly turnSpeed: number = 0.07; // Turning rate
  protected readonly friction: number = 0.98; // Friction coefficient
  protected readonly stopSpeedThreshold = 0.01;

  move(deltaTimeMultiplier: number, direction: { x: number; y: number }): void {
    const dt = deltaTimeMultiplier;

    // Forward/backward input: direction.y
    if (direction.y > 0) {
      // Accelerating forward
      const accelX = Math.cos(this._rotation) * this.acceleration * dt;
      const accelY = Math.sin(this._rotation) * this.acceleration * dt;
      this.velocityX += accelX;
      this.velocityY += accelY;
    } else if (direction.y < 0) {
      // Braking
      const brakeX = Math.cos(this._rotation) * this.brakingPower * dt;
      const brakeY = Math.sin(this._rotation) * this.brakingPower * dt;
      this.velocityX -= brakeX;
      this.velocityY -= brakeY;
    } else {
      // direction.y === 0
    }

    // Limit max speed
    const speed = Math.sqrt(this.velocityX ** 2 + this.velocityY ** 2);
    if (speed > this.maxSpeed) {
      const scale = this.maxSpeed / speed;
      this.velocityX *= scale;
      this.velocityY *= scale;
    }

    // Friction
    this.velocityX *= this.friction;
    this.velocityY *= this.friction;

    // Turning input: direction.x
    if (Math.abs(speed) > this.stopSpeedThreshold) {
      // Adjust angular velocity based on speed and input
      this.angularVelocity = direction.x * this.turnSpeed * dt;
      this._rotation += this.angularVelocity;
    }

    // Update position based on velocity
    this.x += this.velocityX * dt;
    this.y += this.velocityY * dt;

    // Update shapes
    this.shapes.forEach((shape) => {
      shape.x = this.x;
      shape.y = this.y;
      shape.rotation = this.rotation;
    });
  }
}
