import { Spritesheet } from "./Spritesheet";

export class PlayerSprite extends Spritesheet {
  constructor(
    x: number,
    y: number,
    public width: number,
    public height: number
  ) {
    const spritesheets = [
      {
        totalFrames: 8,
        framesPerRow: 3,
        file: require("../Sprites/Green_SUPERCAR_CLEAN_8D_000-sheet.png"),
      },
      {
        totalFrames: 48,
        framesPerRow: 7,
        file: require("../Sprites/Green_SUPERCAR_CLEAN_All_000-sheet.png"),
      },
    ];
    const color = "#000"; // Not used
    const { totalFrames, framesPerRow, file } = spritesheets[1];
    const spritesheet = new Image();
    // spritesheet.src = require("../Sprites/Green_SUPERCAR_CLEAN_All_000-sheet.png");
    spritesheet.src = file;
    super(x, y, width, height, color, spritesheet, totalFrames, framesPerRow);
  }

  set rotation(value: number) {
    // super.rotation = value;
    const rotationDegrees = ((value * 180) / Math.PI + 360) % 360;
    // console.log(`Rotation: ${value} radians = ${rotationDegrees}°`);
    const degreesPerFrame = 360 / this.totalFrames;
    const frameDegreeOffset = degreesPerFrame / 2;
    // console.log(`Frame degree offset: ${frameDegreeOffset}`);
    const frameIndex = Math.floor(
      ((720 + rotationDegrees + frameDegreeOffset) / 360) * this.totalFrames
      // ((720 + rotationDegrees) / 360) * this.totalFrames
    );
    // console.log(`Frame Index: ${frameIndex % this.totalFrames}`);
    this.setFrame(frameIndex % this.totalFrames);
    // console.log(`PlayerSprite Rotation: ${this._rotation}`);
  }
}
