import { ColorTrail } from "./ColorTrail";
import { Coordinates } from "./Types";
import { Shape } from "./Drawing/Shape";
import { PlayerSprite } from "./Drawing/PlayerSprite";
import { CarMovingBody } from "./CarMovingBody";

export class Player extends CarMovingBody {
  private readonly trail: ColorTrail;
  public readonly shapes: Shape[];

  constructor(
    x: number,
    y: number,
    public readonly width: number,
    public readonly height: number,
    protected collisionChecker: (shape: Shape) => boolean,
    trailLength: number = 100 // Maximum length of the trail
  ) {
    super(x, y);

    this.shapes = [
      new PlayerSprite(x, y, width, height),
      // new Rect(x, y, width, height),
    ];
    this.trail = new ColorTrail(trailLength);
  }

  move(deltaTimeMultiplier: number, direction: { x: number; y: number }): void {
    super.move(deltaTimeMultiplier, direction);
    this.trail.tryAdd(this.shapes[0].center());
  }

  draw(
    ctx: CanvasRenderingContext2D,
    scale: number,
    offset: Coordinates
  ): void {
    this.trail.draw(ctx, scale, offset);
    this.shapes.forEach((shape) => {
      shape.draw(ctx, scale, offset);
    });
  }
}
