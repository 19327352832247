// Spritesheet.ts

import { Shape } from "./Shape";
import { Coordinates } from "../Types";

export class Spritesheet extends Shape {
  constructor(
    x: number,
    y: number,
    public width: number,
    public height: number,
    color: string,
    protected spritesheet: HTMLImageElement,
    public readonly totalFrames: number,
    public readonly framesPerRow: number,
    public currentFrame: number = 0
  ) {
    super(x, y, color);
  }

  toString(): string {
    return `[Spritesheet (${this.x}, ${this.y}, frame: ${this.currentFrame})]`;
  }

  within(x: number, y: number): boolean {
    return (
      x >= this.x &&
      x <= this.x + this.width &&
      y >= this.y &&
      y <= this.y + this.height
    );
  }

  outside(x: number, y: number): boolean {
    return !this.within(x, y);
  }

  center(): { x: number; y: number } {
    return { x: this.x + this.width / 2, y: this.y + this.height / 2 };
  }

  top(): { x: number; y: number } {
    return { x: this.x + this.width / 2, y: this.y };
  }

  bottom(): { x: number; y: number } {
    return { x: this.x + this.width / 2, y: this.y + this.height };
  }

  right(): { x: number; y: number } {
    return { x: this.x + this.width, y: this.y + this.height / 2 };
  }

  left(): { x: number; y: number } {
    return { x: this.x, y: this.y + this.height / 2 };
  }

  topright(): { x: number; y: number } {
    return { x: this.x + this.width, y: this.y };
  }

  bottomright(): { x: number; y: number } {
    return { x: this.x + this.width, y: this.y + this.height };
  }

  bottomleft(): { x: number; y: number } {
    return { x: this.x, y: this.y + this.height };
  }

  topleft(): { x: number; y: number } {
    return { x: this.x, y: this.y };
  }

  /**
   * Sets the current frame to draw.
   */
  setFrame(frameIndex: number) {
    if (frameIndex >= 0 && frameIndex < this.totalFrames) {
      this.currentFrame = frameIndex;
    } else {
      console.warn(`Invalid frame index: ${frameIndex}`);
    }
  }

  /**
   * Draws the specified frame of the sprite sheet.
   */
  draw(ctx: CanvasRenderingContext2D, scale: number, offset: Coordinates) {
    if (!this.spritesheet.complete || this.spritesheet.naturalWidth === 0) {
      console.warn("Sprite sheet not loaded, skipping draw.");
      return;
    }

    const frameWidth = this.spritesheet.width / this.framesPerRow;
    const frameHeight =
      this.spritesheet.height / Math.ceil(this.totalFrames / this.framesPerRow);

    const frameX = (this.currentFrame % this.framesPerRow) * frameWidth;
    const frameY =
      Math.floor(this.currentFrame / this.framesPerRow) * frameHeight;

    ctx.save();
    ctx.translate(offset.x + scale * this.x, offset.y + scale * this.y);
    // console.log(`Spritesheet rotation: ${this._rotation}`);
    ctx.rotate(this._rotation);

    ctx.drawImage(
      this.spritesheet,
      frameX, // Source X
      frameY, // Source Y
      frameWidth, // Source Width
      frameHeight, // Source Height
      (-scale * this.width) / 2, // Destination X
      (-scale * this.height) / 2, // Destination Y
      2 * scale * this.width, // Destination Width
      2 * scale * this.height // Destination Height
    );

    ctx.restore();
  }
}
