import "../../styles/movie-table.scss";
import moviesData from "../../data/movies.json";
import imdbIcon from "../../icons/IMDB_Logo_2016.svg";
import rottenTomatoesIcon from "../../icons/Rotten_Tomatoes.png";
import letterboxdIcon from "../../icons/letterboxd-decal-dots-pos-rgb.svg";
import React, { useState, useMemo } from "react";

const MovieNight = () => {
  const [movies] = useState(moviesData);
  const [sortConfig, setSortConfig] = useState({
    key: "watchDate",
    direction: "descending",
  });
  const service_icons = {
    imdb: imdbIcon,
    rottenTomatoes: rottenTomatoesIcon,
    letterboxd: letterboxdIcon,
  };

  const sortedMovies = useMemo(() => {
    let sortableMovies = [...movies];
    sortableMovies.sort((a, b) => {
      const va = a[sortConfig.key] ? a[sortConfig.key] : "";
      const vb = b[sortConfig.key] ? b[sortConfig.key] : "";
      if (va < vb) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (va > vb) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortableMovies;
  }, [movies, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const SortableHeader = ({ label, sortKey }) => (
    <th className={sortKey}>
      <button onClick={() => requestSort(sortKey)}>
        {label}
        <span aria-hidden="true">
          {sortConfig.key === sortKey
            ? sortConfig.direction === "ascending"
              ? " ▲"
              : " ▼"
            : ""}
        </span>
      </button>
    </th>
  );

  const LinkCell = ({ service, sid, icon, title }) => {
    let link;
    if (service === "imdb") {
      link = `https://www.imdb.com/title/${sid}/`;
    } else if (service === "rottenTomatoes") {
      if (sid.startsWith("tv/")) {
        link = `https://www.rottentomatoes.com/${sid}`;
      } else {
        link = `https://www.rottentomatoes.com/m/${sid}`;
      }
    } else if (service === "letterboxd") {
      link = `https://letterboxd.com/film/${sid}/`;
    } else {
      return null;
    }
    return !sid ? null : (
      <a href={link}>
        <img
          src={icon}
          alt={`${service} link for ${title}`}
          style={{ width: "38px", height: "32px" }}
        />
      </a>
    );
  };

  // const CommentCell = ({ comment }) => (
  //   <td className="comment">{comment || ""}</td>
  // );

  const UpNext = ({ href = "#LINKHERE" }) => (
    <div
      className="up-next"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <a href={href}>Up Next!</a>
    </div>
  );

  const watchYearCounts = useMemo(() => {
    return movies.reduce((acc, movie) => {
      const year = movie.watchDate
        ? new Date(movie.watchDate).getFullYear()
        : "Unknown";
      acc[year] = (acc[year] || 0) + 1;
      return acc;
    }, {});
  }, [movies]);

  return (
    <div id="root">
      <h1 style={{ textAlign: "center" }}>Movie Night</h1>
      <div
        className="movie-counter"
        style={{ textAlign: "center", marginBottom: "1rem" }}
      >
        <h2>Movie Count</h2>
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
          {Object.entries(watchYearCounts)
            .sort(([yearA], [yearB]) => {
              if (yearA === "Unknown") return -1; // "Unknown" at the top
              if (yearB === "Unknown") return 1;
              return parseInt(yearA) - parseInt(yearB); // Sort other years numerically
            })
            .map(([year, count]) => (
              <li
                key={year}
                style={{
                  margin: "0.5rem 0",
                  fontSize: "1rem",
                  fontWeight: "normal",
                }}
              >
                {year}: {count} movies
              </li>
            ))}
          <li
            key={-1}
            style={{
              margin: "0.5rem 0",
              fontSize: "1rem",
              fontWeight: "bold",
              borderTop: "1px solid #ccc",
              paddingTop: "0.5rem",
            }}
          >
            Total: {movies.length} movies
          </li>
        </ul>
      </div>

      <UpNext />
      <div className="movie-table">
        <table
          className="sortable"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <caption>
            <h3>Movie Picks</h3>
          </caption>
          <thead>
            <tr>
              <SortableHeader label="Movie Title" sortKey="title" />
              <SortableHeader label="Release Year" sortKey="year" />
              <SortableHeader label="Picked By" sortKey="pickedBy" />
              <SortableHeader label="Watch Date" sortKey="watchDate" />
              <SortableHeader label="Runtime" sortKey="runtime" />
              <th className="links">Links</th>
              {/* <th className="comment">Comment</th> */}
            </tr>
          </thead>
          <tbody>
            {sortedMovies.map((movie, index) => (
              <tr key={index}>
                <td className="movie-title">{movie.title}</td>
                <td className="movie-year">{movie.year}</td>
                <td className="picked-by">{movie.pickedBy}</td>
                <td className="watch-date">{movie.watchDate}</td>
                <td className="runtime">
                  {movie.runtime ? `${movie.runtime}m` : ""}
                </td>
                <td className="links">
                  {["imdb", "rottenTomatoes", "letterboxd"].map(
                    (service, i) => (
                      <LinkCell
                        key={i}
                        service={service}
                        sid={movie[`${service}Id`]}
                        icon={service_icons[service]}
                        title={movie.title}
                      />
                    )
                  )}
                </td>
                {/* <CommentCell comment={movie.comment} /> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MovieNight;
